// Config object to be passed to MSAL on creation
export const msalConfig = {
  auth: {
    authority: process.env.REACT_APP_SSO_ENDPOINT
      ? process.env.REACT_APP_SSO_ENDPOINT
      : 'https://login.microsoftonline.com/906aefe9-76a7-4f65-b82d-5ec20775d5aa',
    clientId: process.env.REACT_APP_SSO_CLIENTID,
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: 'localStorage'
  }
};
// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
  state: encodeURI(window.location.href)
};

export const apiRequest = {
  apiKey: 'ac63b8dfca3cec7c28ec7a4c2dd8a472'
};
