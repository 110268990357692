import { useEffect, useState } from 'react';
import { useSignedUrl } from '../../hooks';
import { Spinner } from '@comcast-sir/ui-library';
import { isAndroid, AndroidView } from 'react-device-detect';
import { useMsal } from '@azure/msal-react';

const ViewPdf = () => {
  const url = window.location.href;
  const urlSplited = url.split('?');
  const pdfSplited = urlSplited.length > 1 ? urlSplited[1].split('=') : null;
  const pathSplitted = urlSplited.length > 1 ? pdfSplited[1].split('/') : null;
  const endPath =
    urlSplited.length > 1 ? pathSplitted[pathSplitted.length - 1] : null;
  //const pdfSplited = urlSplited[1].split('=');
  //const pathSplitted = pdfSplited[1].split('/');
  //const endPath = pathSplitted[pathSplitted.length - 1];
  const signedUrlLink = useSignedUrl(endPath);
  const [pageViewUrl, setPageViewUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { instance } = useMsal();

  useEffect(() => {
    setIsLoading(true);
    const replaceHref = async aUrl => {
      document.location.replace(aUrl);
    };

    // For SSO Redirect. This is a workaround to get the 'state'
    const redirProm = instance.controller?.redirectResponse?.get('');
    redirProm.then(function (response) {
      if (!response) {
        return;
      }
      replaceHref(response.state);
      return;
    });

    if (signedUrlLink) {
      if (isAndroid) {
        setPageViewUrl(
          '/pdfjs-4.0.189-legacy-dist/web/viewer.html?file=' +
            encodeURIComponent(signedUrlLink)
        );
      } else {
        replaceHref(signedUrlLink);
      }
    }
  }, signedUrlLink);
  return (
    <div>
      <AndroidView>
        <div
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            top: '75px',
            left: '0px',
            zIndex: '10'
          }}
        >
          <iframe src={pageViewUrl} height="100%" width="100%"></iframe>
        </div>
      </AndroidView>
      <Spinner show={!isLoading} />
    </div>
  );
};

export default ViewPdf;
